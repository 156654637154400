<template>
  <div>
    <div class="img">
      <img :src="logo" alt="" srcset="" />
    </div>
    <div class="inp">
      <input
        type="text"
        onkeyup="this.value=this.value.replace(/[\u4e00-\u9fa5/\s+/]/ig,'')"
        placeholder="请输入账号"
        v-model="account"
      />
      <input
        type="password"
        onkeyup="this.value=this.value.replace(/[\u4e00-\u9fa5/\s+/]/ig,'')"
        placeholder="请输入密码"
        v-model="password"
      />
    </div>
    <button @click="register">注册</button>
    <!-- <p class="txt">返回登录</p> -->
  </div>
</template>

<script>
import Vue from "vue";
import { Dialog } from "vant";
import axios from 'axios'
// const axios = require("axios");
const qs = require("qs");
import { Toast } from "vant";
axios.defaults.withCredentials=true;
Vue.use(Toast);
export default {
  data() {
    return {
      logo: require("../../assets/img/logo.png"),
      account: "",
      password: "",
      utl: this.$url,
    };
  },
  methods: {
    register() {
      let that = this;
      let data1 = {};
      let account = this.account;
      let password = this.password;
      if (!account) {
        Dialog({ message: "请填写账号" });
        return;
      }
      if (!password) {
        Dialog({ message: "请填写密码" });
        return;
      }
      data1.account = account;
      data1.password = password;
      console.log(data1);
      axios
        .post(this.utl + "/front/register", qs.stringify(data1))
        .then(function (res) {
          console.log(res);
          if (res.data.status == 1) {
            Toast.success("注册成功");
            that.$router.push("/login");
          } else {
            Dialog({ message: res.data.msg });
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.img {
  width: 100%;
  text-align: center;
}
.img img {
  width: 30vw;
  height: auto;
  margin-top: 70px;
}

.inp {
  padding-top: 9vh;
}
input {
  width: 80%;
  margin-left: 10%;
  height: 50px;
  margin-bottom: 10px;
  border-bottom: 1px solid #d4d4d4;
}

button {
  width: 80%;
  margin-left: 10%;
  height: 40px;
  margin-top: 6vh;
  color: white;
  background-color: #6495ed;
  border: 2px solid #4f94cd;
  border-radius: 3px;
}

.txt {
  text-align: center;
  position: fixed;
  bottom: 0;
  height: 60px;
  line-height: 60px;
  color: #9c9c9c;
  width: 100%;
}
</style>